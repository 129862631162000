import React from 'react';
import './scss/BlackTags.scss';

const BlackTags = () => {
  return (
    <section className="black-tags">
        <div className="wrapper black-tags__wrapper">
            <p className="h3 black-tags__title">Внедряем product discovery - фокусируемся на решении целей бизнеса через функционал IT-продукта.</p>
            <div className="black-tags-container" >
                <div className="black-tags-itembox">
                    <div className="item item1" data-aos="fade-up" data-aos-duration="500" data-aos-delay="600" data-aos-once="false">Аналитика</div>
                </div>
                <div className="black-tags-itembox">
                    <div className="item item2" data-aos="fade-up" data-aos-duration="500" data-aos-delay="600" data-aos-once="false">Бэклог гипотез</div>
                </div>
                
                <div className="black-tags-itembox">
                    <div className="item item3" data-aos="fade-up" data-aos-duration="500" data-aos-delay="600" data-aos-once="false">Приоритизация</div>
                </div>
                
                <div className="black-tags-itembox">
                    <div className="item item4" data-aos="fade-up" data-aos-duration="500" data-aos-delay="600" data-aos-once="false">Дизайн</div>
                </div>
                
                <div className="black-tags-itembox">
                    <div className="item item5" data-aos="fade-up" data-aos-duration="500" data-aos-delay="600" data-aos-once="false">Разработка</div>
                </div>
                
                <div className="black-tags-itembox">
                    <div className="item item6" data-aos="fade-up" data-aos-duration="500" data-aos-delay="600" data-aos-once="false">Тестирование</div>
                </div>
                
                <div className="black-tags-itembox">
                    <div className="item item7" data-aos="fade-up" data-aos-duration="500" data-aos-delay="600" data-aos-once="false">Контроль метрик</div>
                </div>
                <div className="line-grid">
                    <div className="line-grid-item"></div>
                    <div className="line-grid-item"></div>
                    <div className="line-grid-item"></div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default BlackTags
