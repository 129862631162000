import React, { useEffect, useRef, useState } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import './assets/sass/main.scss';
import MainPage from './components/Pages/MainPage/MainPage';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import ProjectPage from './components/Pages/ProjectPage/ProjectPage';
import AgencyPage from './components/Pages/AgencyPage/AgencyPage';
import WithDeviceType from './components/HOCs/WithDeviceType';
import Menu from './components/Common/Menu';
import ProjectsPage from './components/Pages/ProjectsPage/ProjectsPage';
import ServicesPage from './components/Pages/ServicesPage/ServicesPage';
import ArticlesPage from './components/Pages/ArticlesPage/ArticlesPage';
import ArticlePage from './components/Pages/ArticlePage/ArticlePage';
import ContactsPage from './components/Pages/ContactsPage/ContactsPage';
import PromotionStorePage from './components/Pages/PromotionStorePage';
import AppSuccessPage from './components/Pages/AppSuccessPage';
import BriefPage from './components/Pages/BriefPage/BriefPage';
import PageNotFound from './components/Pages/404Page/404';
import { AppProvider } from './AppContext';
import { AnimatePresence } from 'framer-motion';
import { YMInitializer } from 'react-yandex-metrika';
import ReactPixel from 'react-facebook-pixel';
import GA from 'react-ga';
import { scrollInit } from './assets/vendors/js/scroll';
import ConsultationPage from './components/Pages/ConsultationPage/ConsultationPage';
import PapersPage from './components/Pages/PapersPage/PapersPage';
import './assets/sass/kit.scss';
import OtraslExpertiza from './components/Pages/OtraslExpertiza/OtraslExpertiza';
import EcommercePage from './components/Pages/EcommercePage/EcommercePage';
import ProizvodstvoPage from './components/Pages/ProizvodstvoPage/ProizvodstvoPage';
import NedwizPage from './components/Pages/NedwizPage/NedwizPage';
import { isMobile } from 'react-device-detect';

const App = ({ location }) => {
  let [isIndexPage, setIsIndexPage] = useState(false);
  let mainRef = useRef(null);
  GA.initialize('UA-196738450-1');
  GA.pageview(window.location.pathname + window.location.search);
  ReactPixel.init('450484779497030');
  ReactPixel.pageView();

  useEffect(() => {
    Aos.init({
      duration: 1000,
      once: false,
      disable: false,
      initClassName: 'aos-init',
      animatedClassName: 'aos-animate',
      disableMutationObserver: false,
      debounceDelay: 50,
      throttleDelay: 99,
      offset: isMobile ? 200 : 300 ,
      delay: isMobile ? 100:  300,
      anchorPlacement: isMobile ? 'top-center' : 'top-bottom'
    })
  }, [])

  return (
    <main className="main" ref={mainRef}>
      <YMInitializer
        accounts={[48220988, 85053448]}
        options={{ clickmap: true, trackLinks: true, accurateTrackBounce: true, webvisor: true }}
        version="2"
      />
      <AppProvider mainRef={mainRef}>
        <Menu isIndexPage={isIndexPage} />
        <AnimatePresence exitBeforeEnter>
          <Switch location={location} key={location.pathname}>
        
            <Route exact path="/projects/vkusno-i-bistro">
              <Redirect to="/projects/vkusno-i-bystro" />
            </Route>
            <Route
              exact
              path="/"
              render={() => <MainPage isIndexPage={isIndexPage} setIsIndexPage={setIsIndexPage} />}
            />
            <Route
              exact
              path="/industries"
              render={() => <OtraslExpertiza isIndexPage={isIndexPage}/>}
            />
             <Route
              exact
              path="/ecommerce"
              render={() => <EcommercePage isIndexPage={isIndexPage}/>}
            />
            <Route
              exact
              path="/manufacturing"
              render={() => <ProizvodstvoPage isIndexPage={isIndexPage}/>}
            />
            <Route
              exact
              path="/realty"
              render={() => <NedwizPage isIndexPage={isIndexPage}/>}
            />
            <Route
              exact
              path="/projects"
              render={() => {
                return <ProjectsPage isIndexPage={isIndexPage} />;
              }}
            />
            <Route path="/projects/:projectName" render={() => <ProjectPage />} />
            <Route exact path="/agency" render={() => <AgencyPage isIndexPage={isIndexPage} />} />
            <Route
              exact
              path="/services/:serviceName?"
              render={() => <ServicesPage isIndexPage={isIndexPage} />}
            />
            {/*<Route path="/services/:servicesName" render={() => <ServicesItemPage/>}/>*/}
            <Route
              exact
              path="/articles"
              render={() => <ArticlesPage isIndexPage={isIndexPage} />}
            />
            <Route exact path="/article" render={() => <ArticlePage isIndexPage={isIndexPage} />} />
            <Route
              exact
              path="/contacts"
              render={() => <ContactsPage isIndexPage={isIndexPage} />}
            />
            <Route
              exact
              path="/papers"
              render={() => <PapersPage isIndexPage={isIndexPage} />}
            />
            <Route exact path="/brief" render={() => <BriefPage />} />
            <Route
              exact
              path="/razrabotkaim"
              render={() => (
                <PromotionStorePage isIndexPage={isIndexPage} setIsIndexPage={setIsIndexPage} />
              )}
            />
            <Route
              exact
              path="/success"
              render={() => (
                <AppSuccessPage isIndexPage={isIndexPage} setIsIndexPage={setIsIndexPage} />
              )}
            />
            <Route
              exact
              path="/consultation"
              render={() => <ConsultationPage isIndexPage={isIndexPage} />}
            />
            <Route path="*" render={PageNotFound} />
          </Switch>
        </AnimatePresence>
      </AppProvider>
    </main>
  );
};

export default WithDeviceType(withRouter(App));
