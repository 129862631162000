import React, { Children } from 'react'
import './scss/TextSection.scss';

const TextSection = ({children, ...props}) => {
  return (
    <section className="text-section" data-aos="fade-up">
        <div className="wrapper text-section__wrapper">
            <p className="h3 text-section__title">
              {children}
            </p>
        </div>
    </section>
  )
}

export default TextSection