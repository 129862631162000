import React, { useEffect, useState } from 'react';
import HeaderSection from './MainPageSections/HeaderSection';
import ContactsSection from './MainPageSections/ContactsSection';
import WithDeviceType from '../../HOCs/WithDeviceType';
import 'swiper/swiper.scss';
import Helmet from 'react-helmet';
import { useAppContext } from '../../../AppContext';
import WithTransition from '../../HOCs/WithTransition';
import './MainPage.scss';
import { NavLink, useHistory } from 'react-router-dom';
import ym from 'react-yandex-metrika';
import ReactGA from 'react-ga';
import RazrabotkaSection from './MainPageSections/RazrabotkaSection';
import ExpertizaSection from './MainPageSections/ExpertizaSection';
import NewsSection from './MainPageSections/NewsSection';
import TextSection from './MainPageSections/TextSection';
import SocialSection from './MainPageSections/SocialSection';
import HistorySection from './MainPageSections/HistorySection';
import BlackTags from './MainPageSections/BlackTags';
import BlackButton from '../../Common/BlackButton/BlackButton';
import VakancySection from './MainPageSections/VakancySection';
import AsapLive from './MainPageSections/AsapLive';
import { useDeviceType } from '../../../hooks';

import stroy from '../../../assets/images/projects/newProjects/stroy.png';
import tavrida from '../../../assets/images/projects/newProjects/tavrida.png';
import torex from '../../../assets/images/projects/newProjects/torex.png';
import vkusnoAnd from '../../../assets/images/projects/newProjects/vkusno.png';
import rvi from '../../../assets/images/projects/newProjects/rvi.png';
import yacars from '../../../assets/images/projects/newProjects/yacar.jpg';


import totalCash from '../../../assets/images/projects/newProjects/totalcash.png';
import himark from '../../../assets/images/projects/newProjects/himark.png';
import vitrina from '../../../assets/images/projects/newProjects/vitrina.png';

const MainPage = ({ isIndexPage, setIsIndexPage }) => {
  const { initPage, mainRef } = useAppContext();
  const projectsFirst = [
    {
        "id": 149,
        "title": "Строй-С",
        "tags": [
            "Интернет-магазин",
        ],
        "types": [
            "E-commerce & Retail"
        ],
        "link": "stroi-s",
        "pic": stroy,
        "description": "Увеличили выручку интернет-магазина сети строительных гипермаркетов на 80% за счёт конверсии и среднего чека покупки.",
        "website": "https://stroy-s.org"
    },
    {
      "id": 150,
      "title": "Таврида Электрик",
      "tags": [
          "Мобильное приложение",
      ],
      "types": [
          "PromTech"
      ],
      "link": "tavrida-elektrik",
      "pic": tavrida,
      "description": "Мобильное приложение для управление реклоузером без интернета с интеграцией по проприетарному протоколу.",
    },
    {
      "id": 151,
      "title": "Torex Premium",
      "tags": [
          "Корпоративный сайт",
          "Онлайн-конфигуратор"
      ],
      "types": [
          "E-commerce & Retail"
      ],
      "link": "torex-premium",
      "pic": torex,
      "description": "Увеличили объем продаж входных дверей в салонах, внедрив онлайн-конфигуратор.",
    },
    {
      "id": 152,
      "title": "Вкусно и быстро",
      "tags": [
          "Мобильное приложение"
      ],
      "types": [
          "E-commerce & Retail",
          "FoodTech"
      ],
      "link": "vkusno-i-bystro",
      "pic": vkusnoAnd,
      "description": "Собственная доставка для ресторанов быстрого питания."
    },
    {
      "id": 153,
      "title": "RVI",
      "tags": [
          "Интернет-магазин",
      ],
      "types": [
          "E-commerce & Retail",
          "PromTech"
      ],
      "link": "rvi",
      "pic": rvi,
      "description": "Интернет-магазин одного из лидеров систем безопасности в России и СНГ.",
      "website": "https://rvigroup.ru"
    },
    {
      "id": 154,
      "title": "Yacar",
      "tags": [
          "Web-cервис",
      ],
      "types": [
          "E-commerce & Retail",
      ],
      "link": "yacar",
      "pic": yacars,
      "description": "Автомобильный онлайн-аукцион. Интеграции с 3-мя сервисами иностранных аукционов."
    },
  ]

  const projectsCompany = [
    {
      "id": 249,
      "title": "Total Cash",
      "tags": [
        
      ],
      "types": [
          "FinTech"
      ],
      "pic": totalCash,
      "description": "Сервис для управления финансами в совместном имуществе и бизнес-проектах.",
      "website": "https://promo.total-cash.ru/"
    },
    {
      "id": 250,
      "title": "Hi mark",
      "tags": [
        
      ],
      "types": [
          "PromTech"
      ],
      "pic": himark,
      "description": 'Программный комплекс для автоматизации обязательной маркировки "Честный знак" на производствах.',
      "website": "https://up-line.online/#rec496292031"
    },
    {
      "id": 251,
      "title": "Vitrina",
      "tags": [
        
      ],
      "types": [
          "E-commerce"
      ],
      "pic": vitrina,
      "description": 'Сервис для быстрого запуска отраслевых маркетплейсов на базе telegram-каналов.',
      "website": "https://vitrina-market.ru/"
    },
  ]
  const { mobile } = useDeviceType();
  const history = useHistory();

  const [isProjectsPage] = useState(history.location.pathname === '/projects');

  const getImgSrc = (index, item) => {
      if (isProjectsPage) {
      return index !== 0 && (index + 1) % 3 === 0 ? item.mainPic : item.pic;
      }
      return item.pic;
  };


  useEffect(() => {
    setIsIndexPage(true);
    isIndexPage && mainRef.current.closest('body').classList.add('main-page');


    return () => {
      setIsIndexPage(false);
      mainRef.current.closest('body').classList.remove('main-page');
    };
  }, [isIndexPage]);

  useEffect(() => {
    initPage();
  }, []);


  return (
    <>
      <Helmet>
        <title>ASAP. Разработка программного обеспечения и сложных сайтов</title>
        <meta
          name="description"
          content="Digital-решения для амбициозных компаний. Разработка сайтов и цифровых продуктов. Комплексный интернет-маркетинг."
        />

      </Helmet>

      <HeaderSection />
      
      <RazrabotkaSection />

      <ExpertizaSection
        titleText="Отраслевая экспертиза в E-commerce & Retail, PromTech и PropTech"
      >
        <div className="grid-cards">
          {projectsFirst.map((item, i) => {
              return (
                <NavLink
                  key={i}
                  data-aos="fade-up"
                  to={{
                    pathname: `/projects/${item.link}`,
                    state: {
                      link: item.link
                    }
                  }}
                  className="projects-gallery__project"
                >
                  <div className="projects-gallery__project-pic">
                    {mobile ? (
                      <div className="projects-gallery__project-pic-wrapper">
                        <img
                          src={getImgSrc(i, item)}
                          alt={item.title}
                          className="projects-gallery__project-img"
                        />
                      </div>
                    ) : (
                      <img
                        src={getImgSrc(i, item)}
                        alt={item.title}
                        className="projects-gallery__project-img"
                      />
                    )}
                  </div>

                  <div className="projects-gallery__project-info">
                    <span className="projects-gallery__project-title">{item.title}</span>
                    <p className="label-text projects-gallery__text">
                      {item.description}
                    </p>
                    {!mobile && (
                      <div className="projects-gallery__project-tags">
                        {item.types.map((tag, i) => (
                          <span key={i} className="projects-gallery__project-type">
                            {tag}
                          </span>
                        ))}
                        {item.tags.map((tag, i) => (
                          <span key={i} className="projects-gallery__project-tag">
                            {tag}
                          </span>
                        ))}
                      </div>
                    )}
                  </div>

                  {mobile && (
                    <div className="projects-gallery__project-tags">
                      {item.types.map((tag, i) => (
                        <span key={i} className="projects-gallery__project-type">
                          {tag}
                        </span>
                      ))}
                      {item.tags.map((tag, i) => (
                        <span key={i} className="projects-gallery__project-tag">
                          {tag}
                        </span>
                      ))}
                    </div>
                  )}
                </NavLink>
              );
          })}
        </div>
      </ExpertizaSection>

      {/* <NewsSection /> */}
      <div data-aos="fade-up">
        <TextSection>
          Анализируем, исследуем и ищем новые инсайты о&nbsp;пользователях и бизнесе. Формируем и тестируем гипотезы для достижения целей клиента. Запускаем MVP продукта и развиваем его на основе метрик.
        </TextSection>
      </div>

      <SocialSection
        titleText="Медиа"
        personText="Для наших клиентов мы являемся технологическими партнерами с отраслевой экспертизой, а не просто подрядчиками"
      >
        <a href="https://t.me/vasilievit" target='_blank' className="telegabox">
          <p className="body-xl">Канал о развитии IT-бизнеса и цифровых продуктов</p>
          <div className="telegabox-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
              <path d="M15 1.875C7.75125 1.875 1.875 7.75125 1.875 15C1.875 22.2488 7.75125 28.125 15 28.125C22.2488 28.125 28.125 22.2488 28.125 15C28.125 7.75125 22.2488 1.875 15 1.875ZM21.5559 10.2C21.3478 12.3844 20.4487 17.6841 19.9912 20.13C19.7972 21.165 19.4166 21.5119 19.0472 21.5456C18.2447 21.6197 17.6362 21.0159 16.8591 20.5059C15.6431 19.7091 14.9569 19.2131 13.7766 18.435C12.4125 17.5359 13.2966 17.0428 14.0738 16.2356C14.2772 16.0247 17.8116 12.81 17.88 12.5184C17.8884 12.4819 17.8969 12.3459 17.8153 12.2747C17.7347 12.2025 17.6156 12.2278 17.5294 12.2466C17.4075 12.2747 15.465 13.5581 11.7038 16.0978C11.1525 16.4766 10.6528 16.6603 10.2056 16.6509C9.7125 16.6406 8.76375 16.3725 8.05875 16.1428C7.19344 15.8616 6.50625 15.7134 6.56625 15.2353C6.59719 14.9869 6.94031 14.7319 7.59375 14.4722C11.6203 12.7181 14.3053 11.5612 15.6487 11.0025C19.4841 9.40688 20.2819 9.13031 20.8013 9.12094C20.9156 9.11906 21.1706 9.14719 21.3366 9.28125C21.4763 9.39469 21.5147 9.5475 21.5334 9.65531C21.5522 9.76312 21.5747 10.0078 21.5569 10.1991L21.5559 10.2Z" fill="#2298D5" />
            </svg>
            <span>t.me/vasilievit</span>
          </div>
        </a>
      </SocialSection>

      <HistorySection />

      <BlackTags />

      <TextSection>
        Формирование идеи, изучение рынка, анализ конкурентов, создание MVP, пивоты, поиск product market fit, масштабирование продукта - всё это мы регулярно проходим на собственных проектах.
      </TextSection>

      <SocialSection
        titleText="Медиа"
        personText="Мы знаем, как зарабатывать на IT-продуктах, и можем говорить на языке бизнеса."
      >
        <NavLink
          to={`/brief`}
          onClick={() => {
            ReactGA.event({
              category: 'appayer_sur_le_bouton',
              action: 'aller_sur_une_autre_page',
              label: 'aller_sur_une_autre_page_toit_de_site'
            });
            ym('reachGoal', 'startproject');
          }}
          className=""
        >
          <BlackButton>Запустим продукт вместе</BlackButton>
        </NavLink>
      </SocialSection>

      <ExpertizaSection
        titleText="Развиваем собственные IT-продукты и стартапы."
      >
        <div className="grid-cards">
          {projectsCompany.map((item, i) => {
              return (
                <a href={item.website}
                  data-aos="fade-up"
                  target='_blank'  
                  className="projects-gallery__project"
                  key={i}
                >
                  <div className="projects-gallery__project-pic">
                    {mobile ? (
                      <div className="projects-gallery__project-pic-wrapper">
                        <img
                          src={getImgSrc(i, item)}
                          alt={item.title}
                          className="projects-gallery__project-img"
                        />
                      </div>
                    ) : (
                      <img
                        src={getImgSrc(i, item)}
                        alt={item.title}
                        className="projects-gallery__project-img"
                      />
                    )}
                  </div>

                  <div className="projects-gallery__project-info">
                    <span className="projects-gallery__project-title">{item.title}</span>
                    <p className="label-text projects-gallery__text">
                      {item.description}
                    </p>
                    {!mobile && (
                      <div className="projects-gallery__project-tags">
                        {item.types.map((tag, i) => (
                          <span key={i} className="projects-gallery__project-type">
                            {tag}
                          </span>
                        ))}
                        {item.tags.map((tag, i) => (
                          <span key={i} className="projects-gallery__project-tag">
                            {tag}
                          </span>
                        ))}
                      </div>
                    )}
                  </div>

                  {mobile && (
                    <div className="projects-gallery__project-tags">
                      {item.types.map((tag, i) => (
                        <span key={i} className="projects-gallery__project-type">
                          {tag}
                        </span>
                      ))}
                      {item.tags.map((tag, i) => (
                        <span key={i} className="projects-gallery__project-tag">
                          {tag}
                        </span>
                      ))}
                    </div>
                  )}
                </a>
              );
          })}
        </div>
      </ExpertizaSection>

      <VakancySection />

      <AsapLive />

      <ContactsSection />
    </>
  );
};

export default WithTransition(WithDeviceType(MainPage));
